/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import 'ngx-toastr/toastr';



body {
    //width: 100vw;
    //height: 100vh;
 /* font-family: 'prompt';*/
  font-family: "Open Sans", serif;
}

::-webkit-scrollbar-track {
  width: 1px;
  background: lightgray;
  overflow: scroll;
}

/* Customize the width and height of the scrollbar */
::-webkit-scrollbar {
  width: 8px; /* Width for vertical scrollbars */
  height: 8px; /* Height for horizontal scrollbars */
  overflow: scroll;
}

/* The track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #6f6f6f; /* Light gray background */
  border-radius: 0px !important; /* Rounded corners */
  overflow: scroll;
}

/* The draggable handle (thumb) */
::-webkit-scrollbar-thumb {
  background: #6f6f6f !important; /* Medium gray handle */
  border-radius: 0px !important; /* Rounded corners for smooth appearance */
  overflow: scroll;
}

/* On hover, change the handle color */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker gray when hovered */
  overflow: scroll;
}

/* Optional: Styling for the scrollbar corners (rarely needed) */
::-webkit-scrollbar-corner {
  background: #fffefe; /* Matches the track background */
  overflow: scroll;
}


.content {
    padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
    overflow: hidden;
    display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
    transform: none;
}

.loader {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader:before {
  content: '';
  width: 48px;
  height: 5px;
  background: #999;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader:after {
  content: '';
  width: 100%;
  height: 100%;
  background: rgb(61, 106, 255);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {

  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

#page {
  display: flex;
  justify-content: center;
  align-items: center;
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#h3 {
  color: rgb(82, 79, 79);
}

#ring {
  width: 190px;
  height: 190px;
  border: 1px solid transparent;
  border-radius: 50%;
  position: absolute;
}

#ring:nth-child(1) {
  border-bottom: 8px solid rgb(240, 42, 230);
  animation: rotate1 2s linear infinite;
}

@keyframes rotate1 {
  from {
    transform: rotateX(50deg) rotateZ(110deg);
  }

  to {
    transform: rotateX(50deg) rotateZ(470deg);
  }
}

#ring:nth-child(2) {
  border-bottom: 8px solid rgb(240, 19, 67);
  animation: rotate2 2s linear infinite;
}

@keyframes rotate2 {
  from {
    transform: rotateX(20deg) rotateY(50deg) rotateZ(20deg);
  }

  to {
    transform: rotateX(20deg) rotateY(50deg) rotateZ(380deg);
  }
}

#ring:nth-child(3) {
  border-bottom: 8px solid rgb(3, 170, 170);
  animation: rotate3 2s linear infinite;
}

@keyframes rotate3 {
  from {
    transform: rotateX(40deg) rotateY(130deg) rotateZ(450deg);
  }

  to {
    transform: rotateX(40deg) rotateY(130deg) rotateZ(90deg);
  }
}

#ring:nth-child(4) {
  border-bottom: 8px solid rgb(207, 135, 1);
  animation: rotate4 2s linear infinite;
}

@keyframes rotate4 {
  from {
    transform: rotateX(70deg) rotateZ(270deg);
  }

  to {
    transform: rotateX(70deg) rotateZ(630deg);
  }
}
/* Improving visualization in light mode */
.logo {
  height: 120px;
  width: 100px;
}
nav a:hover {
  border-bottom: 2px solid #000000;
}
nav a {
  transition: 0.3s ease;
  color: black;
  font-size: 20px;
  text-decoration: none;
  border-bottom: 4px solid #8eff8e;
  padding: 20px 0;
  margin: 0 5px;
  font-size: 17px;
}


.loaderssm {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 5px solid #eef3f2;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 5px solid #ef6210;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 5px solid hsl(129, 86%, 48%);
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


@media (max-width: 768px) {
  .hidden {
    display: none;
  }

  .textSmall {
    font-size: 16px;
  }
}

.form-floating > .form-control::placeholder {
  color: revert;
}

.form-floating > .form-control:not(:focus)::placeholder {
  color: transparent;
}


.btn-view {

  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
  font-size: 12px;
  height: 20px;
  padding: 0px 15px;
  text-shadow: rgb(0 0 0 / 25%) 0px 3px 8px;
  background: linear-gradient(92.88deg, rgb(69, 94, 181) 9.16%, rgb(86, 67, 204) 43.89%, rgb(103, 63, 215) 64.72%);
  transition: all 0.5s ease 0s;

  :hover {
    box-shadow: rgb(80 63 205 / 50%) 0px 1px 40px;
    transition: all 0.1s ease 0s;
  }
}

//badges
.badge-gradient-green {
  box-shadow: #8af38a !important;
  color: #0c622e !important;
  background-color: #dcf1e4 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-gradient-dark-green {
  box-shadow: #8af38a !important;
  color: #0c622e !important;
  background-color: #dcf1e4 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-gradient-blue {
  box-shadow: #8ae3f3 !important;
  color: rgb(8, 70, 130) !important;
  background-color: rgb(115, 188, 253) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-gradient-yellow {
  box-shadow: #ecf38a !important;
  color: #fd7e14 !important;
  background-color: rgb(245, 237, 166) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-gradient-red {
  box-shadow: #fae4e8 !important;
  color: #b03d50 !important;
  background-color: #fae4e8 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-gradient-secondary {
  box-shadow: #f1f2f3 !important;
  color: #404247 !important;
  background-color: #f1f2f3 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-gradient-brown {
  box-shadow: #f1f2f3 !important;
  color: #581a00 !important;
  background-color: #f6973f !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-gradient-info {
  color: #3b7e94 !important;
  background-color: #d2edf5 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.badge-success-green {
  box-shadow: #8af38a !important;
  color: #fff !important;
  background-color: #0c622e !important;
  font-weight: 600 !important;
  display: inline-block;
  outline: none;
  font-size: 16px !important;
  line-height: 20px;
  border-radius: 8px;
  padding: 10px 24px;
  border: none;
  text-align: center;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
}

.badge-primary-blue {
  box-shadow: #8ae3f3 !important;
  color: rgb(8, 70, 130) !important;
  background-color: rgb(115, 188, 253) !important;
  font-weight: 600 !important;
  display: inline-block;
  outline: none;
  font-size: 16px !important;
  line-height: 20px;
  border-radius: 8px;
  padding: 18px 24px;
  border: none;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
}


.badge-active {
  border: 1px solid #CAC4D0;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: #359708 !important;
  font-size: 12px !important;
  margin-top: 2px !important;
}

.badge-inactive {
  border: 1px solid #B43A13;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: #B43A13;
  font-size: 12px !important;
}

.badge-info {
  border: 1px solid #1341b4;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: #1328b4;
  font-size: 12px !important;
}

.badge-suspend {
  border: 1px solid #b47e13;
  border-radius: 25px;
  padding: 10px 20px;
  background-color: #b46613;
  font-size: 12px !important;
}
