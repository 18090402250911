@font-face {
  font-family: "Open Sans";
  font-weight: normal;
  font-style: normal;
  src: url(~assets/fonts/opensans/opensans_regular.ttf) format("truetype");
}
@font-face {
  font-family: "Guest Check";
  font-weight: bold;
  font-style: normal;
  src: url(~assets/fonts/Guest-Check-Regular/Guest-Check-Regular.ttf) format("truetype");
}
